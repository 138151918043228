<script lang="ts">
    import { concatClasses } from '../utils'

    let classNames = ''
    export { classNames as class }
    export let size: 'sm' | 'lg' | '' = ''
    export let variant: 'darker' | 'white' = 'darker'

    $: classes = concatClasses([
        'prose',
        'max-w-full',
        size ? `prose-${size}` : '',
        variant ? `prose-${variant}` : '',
        classNames,
    ])
</script>

<div class={classes} {...$$restProps}>
    <slot />
</div>

<style global lang="postcss">
    .prose {
        max-width: 100%;

        li {
            margin-top: 0;
            margin-bottom: 0.15rem;
        }

        /* stylelint-disable-next-line selector-no-qualifying-type */
        a[target='_blank']::after {
            content: '';
            display: inline-block;
            width: theme('spacing.4');
            height: theme('spacing.4');
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ctitle%3Eopen-in-new%3C/title%3E%3Cpath d='M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z' /%3E%3C/svg%3E");
            background-position: center center;
            background-size: 100%;
        }

        code {
            font-weight: theme('fontWeight.normal');

            &::before,
            &::after {
                content: '';
            }
        }
    }

    .prose.prose-sm {
        font-size: theme('fontSize.sm');
    }

    .prose.prose-lg {
        font-size: theme('fontSize.lg');
        line-height: 1.5;
    }
</style>
